var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "item",
      staticClass: "vue-grid-item",
      class: _vm.classObj,
      style: _vm.style,
    },
    [
      _vm._t("default"),
      _vm.resizableAndNotStatic
        ? _c("span", { ref: "handle", class: _vm.resizableHandleClass })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }